import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  data,
  redirect,
  useLoaderData,
  useRouteLoaderData,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import { useEffect } from 'react';
import { Toaster, toast as hotToast } from 'react-hot-toast';
import { getToast } from 'remix-toast';
import { AppLayout } from '~/components/layouts/app-layout';
import { DocumentLayout } from '~/components/layouts/document-layout';
import { authenticator, validateUser } from '~/services/auth.server';

import styles from './tailwind.css?url';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await authenticator.isAuthenticated(request);
  const url = new URL(request.url);
  const { toast, headers } = await getToast(request);

  // Get environment variables to pass to the client
  const ENV = {
    GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  };

  // allow unauthenticated access to pdf routes
  if (url.pathname.includes('/pdf')) {
    return { isPdfRoute: true, ENV };
  }

  // Allow unauthenticated access to login page
  if (url.pathname === '/login') {
    return data({ user, ENV, toast }, { headers });
  }

  // Redirect to login if not authenticated
  if (!user) {
    return redirect('/login');
  }

  // Check if the user is still active
  const isActive = await validateUser(user.id);
  if (!isActive) {
    // Log out the user if they're no longer active
    return await authenticator.logout(request, {
      redirectTo: '/login?error=account-deactivated',
    });
  }

  return data({ user, ENV, toast }, { headers });
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Links />
        <Meta />
      </head>
      <body>
        {children}
        <Toaster position="bottom-right" />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.ENV)}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  const { isPdfRoute, toast } = useLoaderData<typeof loader>();

  useEffect(() => {
    if (toast) {
      if (toast?.type === 'error') {
        hotToast.error(toast.message);
      }
      if (toast?.type === 'success') {
        hotToast.success(toast.message);
      }
    }
  }, [toast]);

  return isPdfRoute ? (
    <DocumentLayout>
      <Outlet />
    </DocumentLayout>
  ) : (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}

export default withSentry(App);
