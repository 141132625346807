import type { User } from '@prisma/client';
import { useLoaderData, useLocation } from '@remix-run/react';
import { SidebarNav } from '~/components/sidebar-nav';
import { SidebarProvider } from '~/components/ui/sidebar';

interface AppLayoutProps {
  children: React.ReactNode;
}

export function AppLayout({ children }: AppLayoutProps) {
  const { user } = useLoaderData<{ user: User }>();
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  if (isLoginPage) {
    return <>{children}</>;
  }

  return (
    <SidebarProvider>
      <SidebarNav user={user} />
      <main className="w-full p-8 bg-[#f7faff]">{children}</main>
    </SidebarProvider>
  );
}
