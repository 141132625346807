import type { User } from '@prisma/client';
import { Form, Link, useLocation } from '@remix-run/react';
import {
  Cog,
  FileText,
  Home,
  LayoutGrid,
  LogOut,
  MapPin,
  Package,
  ShieldCheck,
  Users,
  Wrench,
} from 'lucide-react';

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '~/components/ui/sidebar';
import { cn } from '~/lib/utils';

import Logo from '~/assets/logo.svg';

interface SidebarNavProps {
  user: User;
}

export function SidebarNav({ user }: SidebarNavProps) {
  const location = useLocation();
  const pathname = location.pathname;

  const navigation = [
    { name: 'Home', href: '/', icon: Home },
    { name: 'Clients', href: '/clients', icon: Users },
    { name: 'Sites', href: '/sites', icon: MapPin },
    { name: 'Quotes', href: '/quotes', icon: FileText },
    { name: 'Projects', href: '/projects', icon: LayoutGrid },
    { name: 'Design Studio', href: '/designer', icon: Wrench },
    { name: 'Equipment', href: '/equipment', icon: Package },
    { name: 'Safety & Compliance', href: '/safety', icon: ShieldCheck },
    {
      name: 'System Settings',
      href: '#',
      icon: Cog,
      children: [
        { name: 'User Management', href: '/settings/users' },
        { name: 'Terms & Conditions', href: '/settings/terms' },
        { name: 'Rate Profiles', href: '/settings/rates' },
        { name: 'Company Information', href: '/settings/company' },
        { name: 'Other Settings', href: '/settings/other' },
      ],
    },
  ];

  function isActiveRoute(href: string): boolean {
    if (href === '/') {
      return pathname === '/';
    }
    return pathname.startsWith(href);
  }

  return (
    <Sidebar>
      <SidebarHeader className="border-none p-4">
        <img alt="Silvercrest Scaffolding" className="w-auto" src={Logo} />
      </SidebarHeader>
      <SidebarContent className="p-2">
        <SidebarMenu>
          {navigation.map((item) => (
            <SidebarMenuItem key={item.name}>
              <SidebarMenuButton
                isActive={isActiveRoute(item.href)}
                className={cn('transition-colors')}
                asChild
              >
                <Link to={item.href} className="flex items-center gap-3">
                  <item.icon className="h-4 w-4" />
                  <span>{item.name}</span>
                </Link>
              </SidebarMenuButton>
              {item.children && (
                <SidebarMenuSub>
                  {item.children.map((child) => (
                    <SidebarMenuSubItem key={child.name}>
                      <SidebarMenuSubButton
                        isActive={isActiveRoute(child.href)}
                        className={cn('transition-colors')}
                        asChild
                      >
                        <Link to={child.href}>{child.name}</Link>
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  ))}
                </SidebarMenuSub>
              )}
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarContent>
      <SidebarFooter className="border-t p-4">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col">
            <span className="text-sm font-medium">
              {user.firstName} {user.lastName}
            </span>
            <span className="text-xs capitalize text-muted-foreground">
              {user.role.toLowerCase()}
            </span>
          </div>
          <Form action="/logout" method="post">
            <button
              type="submit"
              className="flex w-full items-center gap-2 rounded-md px-2 py-1 text-sm text-destructive hover:bg-destructive/10"
            >
              <LogOut className="h-4 w-4" />
              Sign out
            </button>
          </Form>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
